var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal-title"},[(_vm.id)?_c('span',[_vm._v(_vm._s(_vm.l('Edit')))]):_vm._e(),(!_vm.id)?_c('span',[_vm._v(_vm._s(_vm.l('Create')))]):_vm._e()])]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":_vm.l('TagName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'name',
                    {
                        rules: [
                            {
                                required: true,
                                message: _vm.l('BlogNameInputDesc'),
                            },
                            {
                                min: 3,
                                message: _vm.l('MinLength', 3),
                            }
                        ],
                    } ]),expression:"[\n                    'name',\n                    {\n                        rules: [\n                            {\n                                required: true,\n                                message: l('BlogNameInputDesc'),\n                            },\n                            {\n                                min: 3,\n                                message: l('MinLength', 3),\n                            }\n                        ],\n                    },\n                    ]"}],attrs:{"placeholder":_vm.l('TagNameInputDesc')}})],1),_c('a-form-item',{attrs:{"label":_vm.l('BlogName')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'blogId',
                    {
                        rules: [
                        ],
                    } ]),expression:"[\n                    'blogId',\n                    {\n                        rules: [\n                        ],\n                    },\n                    ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.bloglist),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":_vm.l('TagDescription')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'description',
                    {
                        rules: [
                        ],
                    } ]),expression:"[\n                    'description',\n                    {\n                        rules: [\n                        ],\n                    },\n                    ]"}],attrs:{"placeholder":_vm.l('TagDescriptionInputDesc'),"auto-size":{ minRows: 3, maxRows: 5 },"maxLength":"3000"}})],1),_c('a-form-item',{staticClass:"btn--container"},[_c('a-button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.l("Cancel"))+" ")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.l('Save'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }